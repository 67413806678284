import {FC, useState} from 'react';
import styles from './questions.module.scss';

type QuestionsProps = {
  questionsList: {question: string, answer: string | JSX.Element}[]
}

const Questions: FC<QuestionsProps> = ({
  questionsList
}) => {
  
  const [openIndex, setOpenIndex] = useState(null);
  
  const toggleAnswer = (index) => {
      setOpenIndex(openIndex === index ? null : index);
  };
  
  return (
      <div>
        {questionsList.map((item, index) => (
          <div key={index}>
            <div
              className={`${styles.Question} ${
                openIndex === index ? styles.Open : ''
              }`}
              onClick={() => toggleAnswer(index)}
            >
              <p>{item.question}</p>
              <svg className={`${styles.Arrow} ${openIndex === index ? styles.ArrowOpen : ''}`} xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30" fill="none">
                  <path 
                      d="M16.4625 18.2925L12.5825 14.4125L8.7025 18.2925C8.3125 18.6825 7.6825 18.6825 7.2925 18.2925C6.9025 17.9025 6.9025 17.2725 7.2925 16.8825L11.8825 12.2925C12.2725 11.9025 12.9025 11.9025 13.2925 12.2925L17.8825 16.8825C18.2725 17.2725 18.2725 17.9025 17.8825 18.2925C17.4925 18.6725 16.8525 18.6825 16.4625 18.2925Z" 
                      fill="#111111"
                  />
              </svg>
            </div>
            <div
              className={`${styles.Answer} ${
                openIndex === index ? styles.Open : ''
              }`}
            >
              {item.answer}
            </div>
          </div>
        ))}
      </div>
  );
}

export default Questions;