import Head from 'next/head';
import { useRef } from 'react';

import motiveSvg from '../new-images/donation/motive-bg.svg';
import helpSvg from '../new-images/donation/help-bg.svg';
import findSvg from '../new-images/donation/find-bg.svg';
import findSvgMobile from '../new-images/donation/find-bg-mobile.svg';
import helpSvgMobile from '../new-images/donation/help-bg-mobile.svg';
import motiveSvgMobile from '../new-images/donation/motive-bg-mobile.svg';

import Questions from '../components/Questions';
import DonationForm from '../components/donationForm';
import DonateList from '../components/donateList';
import { DonateQuestionsList } from '../entities/questions';

import styles from '../styles/donatePage.module.scss';

const Donation = () => {
    const image = "/static/images/fundraisingMetaImg.png";
    const IWidth = "600";
    const IHeight = "315";

    const donationRef = useRef(null);

    const scrollToDonationForm = () => {
      donationRef.current.scrollIntoView({ behavior: 'smooth'});
    }
    
    return (
        <>
            <Head>
                <meta property="vk:image" content={image} />
                <meta property="twitter:image" content={image} />
                <meta property="og:image" content={image} />
                <meta property="og:image:width" content={IWidth} />
                <meta property="og:image:height" content={IHeight} />
                <link rel="image_src" href={image} />
                <script src="https://widget.cloudpayments.ru/bundles/cloudpayments.js"></script>
            </Head>
            <div className={styles.Page}>
                <section className={styles.OpenSection}>
                    <div className="container">
                        <div className={`${styles.Grid} ${styles.FirstGrid}`}>
                            <div className={styles.Info} ref={donationRef}>
                                <h2 className={styles.H2}>Помочь проекту</h2>
                                <h3 className={styles.H3}>Без людей нет доноров,<br />а без поддержки нет DonorSearch</h3>
                            </div>
                            <DonationForm />
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className={styles.Grid}>
                            <div>
                                <div>
                                    <h2 className={styles.H2}>Регулярная<br /> поддержка<br /> помогает:</h2>
                                    <div className={styles.Cards}>
                                        <div className={styles.Card}>
                                            <img src={motiveSvg} alt="мотивация" />
                                            <img src={motiveSvgMobile} alt="мотивация" />
                                            <p>Мотивировать регулярных доноров приятными бонусами и уникальными предложениями</p>
                                        </div>
                                        <div className={styles.Card}>
                                            <img src={helpSvg} alt="помощь" />
                                            <img src={helpSvgMobile} alt="помощь" />
                                            <p>Найти в себе донора тем, кто сомневается, привлекать внимание к вопросам донорства и просвещать</p>
                                        </div>
                                        <div className={styles.Card}>
                                            <img src={findSvg} alt="поиск" />
                                            <img src={findSvgMobile} alt="поиск" />
                                            <p>Расширять функционал и поддерживать работу сайта</p>
                                        </div>
                                    </div>
                                    <div className={styles.mobileButton}>
                                        <div onClick={scrollToDonationForm}>Пожертвовать</div>
                                    </div>
                                </div>
                                <DonateList />
                                <div className={styles.Questions}>
                                    <h2 className={styles.H2}>Часто задаваемые<br /> вопросы</h2>
                                    <Questions 
                                        questionsList={DonateQuestionsList}
                                    />
                                    <div className={styles.mobileButton}>
                                        <div onClick={scrollToDonationForm}>Пожертвовать</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Donation
  