export const DonateQuestionsList = [
  {question: '🤔 Куда пойдут средства от пожертвований?', answer: 'Ваши пожертвования направляются на поддержку некоммерческого сервиса DonorSearch и его программ. Миссия DonorSearch — сделать так, чтобы каждый день в каждый центр крови приходило нужное число доноров. DonorSearch — это негосударственный и некоммерческий проект, в котором мы мотивируем сдавать кровь и помогаем каждому найти в себе донора. Сопровождаем на этом пути тех, кто впервые принимает это решение и тех, кто с нами уже несколько лет.'},
  {question: '🤨 Зачем вам помогать, я и так сдаю кровь?', answer: 'Спасибо за вашу поддержку и готовность сдавать кровь! Ваше пожертвование поможет нам обеспечить финансовую устойчивость, которая позволит проводить и поддерживать программы по сбору и распределению крови, а также развивать наши программы и привлекать еще больше новых доноров. Без людей нет доноров, без поддержки нет DonorSearch.'},
  {question: '🧐 Где можно посмотреть ваши отчеты и документы?', answer:(<p>Мы предоставляем отчетность и документацию о нашей работе на нашем официальном <a href='/' target='_blank'>сайте</a>. Вы можете найти соответствующую информацию в разделе <a href='/non-profit' target='_blank'>"Об организации"</a>.</p>)},
  {question: '🤑 Как найти информацию о моем пожертвовании?', answer: (<p>Чтобы получить информацию о вашем пожертвовании, вы можете связаться с нашей <a href="mailto:support@donorsearch.org">службой поддержки</a>. Пожалуйста, предоставьте нам дату и сумму пожертвования, а также любую другую доступную информацию, чтобы мы могли быстро найти соответствующую информацию и предоставить вам ответ.</p>)},
  {question: '😎 Что такое регулярные пожертвования?', answer: 'Рекуррентные или регулярные пожертвования - это ежемесячные платежи, которые вы можете настроить на автоматическое списание средств с вашего банковского счета или карты без дополнительного подтверждения списания. За несколько дней до списания вам будет приходить напоминание о платеже, с возможностью его изменить или отменить.'},
  {question: '🧭 Я смогу управлять подпиской на регулярные платежи?', answer: (<p>По вашему запросу на почту <a href="mailto:support@donorsearch.org">support@donorsearch.org</a> мы сможем помочь управлять подпиской: изменить сумму платежа, перенести дату списания, изменить почту для уведомлений или отменить подписку. В любое время вы можете самостоятельно отменить подписку на этой <a href='https://my.cloudpayments.ru/' target='_blank'>странице</a>.</p>)},
  {question: '🧾 Вы сможете предоставить справку для налогового вычета?', answer: (<p>Да, мы предоставляем справки о пожертвованиях, которые можно использовать для возврата налогов в соответствии с законодательством. Пожалуйста, свяжитесь с нашей <a href="mailto:support@donorsearch.org">службой поддержки</a> и предоставьте нам дату и сумму последнего пожертвования, а также любую другую доступную информацию о платежах, чтобы мы могли оперативно подготовить для вас справку.</p>)},
  {question: '🏛️ Как сделать перевод по реквизитам?', answer: (<p>Вы можете <a href="mailto:support@donorsearch.org">связаться</a> с нами и заключить договор на совершение пожертвования как юридическое или физическое лицо. Также вы можете сделать пожертвование путем перевода по реквизитам:<br /> Автономная некоммерческая организация "Институт развития донорства "Донор-Сёрч (Поиск доноров)"<br />  ООО "Банк Точка"<br />  БИК 044525104<br />  ИНН/КПП: 1686035415/168601001<br />  к/сч. 30101810745374525104<br />  р/сч. 40703810720000001947<br />  Назначение: Благотворительное пожертвование</p>)},
  {question: '💰 Как я еще могу сделать пожертвования?', answer: (<p>DonorSearch верифицирован на фандрайзинговых площадках: <a href='https://today.sberbankvmeste.ru/npo/311' target='_blank'>“СберВместе”</a> и <a href='https://dobro.mail.ru/' target='_blank'>VK Добро</a>. Также DonorSearch можно найти в разделе “Благотворительность” приложений <a href='https://www.tinkoff.ru/payments/provider-donorsearchdonor/' target='_blank'>“Тинькофф”</a> и <a href='https://payment.mts.ru/pay/19649' target='_blank'>“МТС”</a>.</p>)},
  {question: '🤯 У меня возникли проблемы при совершении платежа, куда обратиться?', answer: (<p>В случае возникновения проблем при совершении платежа, вы можете обратиться в нашу <a href="mailto:support@donorsearch.org">службу поддержки</a>. Пожалуйста, предоставьте все детали проблемы, а мы постараемся решить ее как можно скорее и помочь вам успешно совершить пожертвование.</p>)},
  {question: '😟 Здесь нет моего вопроса. Куда мне обратиться?', answer: (<p>Напишите нам на почту <a href="mailto:support@donorsearch.org">support@donorsearch.org</a> мы обязательно постараемся вам помочь.</p>)}
];