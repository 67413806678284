import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useRouter } from 'next/router';
import FundraisingDonateCard from '../fundraising/FundraisingDonateCard';
import styles from './styles.module.scss';

const DonateList = ( props ) => {
  const { fundraisingStore } = props;
  const router = useRouter();
  const { operation } = router.query;
  const [isFullList, setFullList] = useState(false);
  const [payments, setPayments] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);

  const getData = async () => {
    if(payments.length === 50 && !isFullList) {
      setFullList(true);
    } else {
      fundraisingStore?.getDonatePayments(page + 1)
      .then((res) => {
        const paymentsArr = [...payments];
        paymentsArr.push(...res.results);
        setPayments(paymentsArr);
        setPage(page + 1);
      })
    }
  };

  useEffect(() => {
    (async () => {
        fundraisingStore?.getDonatePayments()
        .then((res) => {
          setPayments(res.results);
          setTotalCount(res.count)
          setPage(1)
          setFullList(false)
        })
    })();
  }, [operation]);

  return (
    <div className={styles.donations}>
      <h3>Пожертвования</h3>

      <div className={styles.donateList}>
        {
          payments.length ?
          payments.slice(0, isFullList ? payments.length : 10).map((payment) => (
            <FundraisingDonateCard 
              key={payment.id}
              payment={{
                userPhoto: payment?.client?.photo?.small,
                userFirstName: payment?.client?.first_name,
                userLastName: payment?.client?.last_name,
                comment: payment?.client_comment,
                date: payment?.created_at,
                sum: payment?.amount
              }}
            />
          ))
          : <p className={styles.noDonations}>Здесь пока нет пожертвований. Станьте первым 🙂</p>
        }
      </div>
      {
        payments.length > 20 && payments.length !== totalCount &&
        <div className={styles.showMore}>
          <button
            type='button'
            onClick={() => getData()}
          >
            Показать ещё
          </button>
        </div>
      }
    </div>
  );
}

export default inject(
  "fundraisingStore"
)(observer(DonateList));